<template>
  <header>
    <div class="navbar">
      <a href="#hero" class="navbar__logo">Home</a>

      <ul class="navbar__main">
        <li class="navbar__main-link">
          <a href="#projects">Projects</a>
        </li>
        <li class="navbar__main-link">
          <a href="#about">About me</a>
        </li>
        <li class="navbar__main-link">
          <a href="#contact">Contact me</a>
        </li>
      </ul>
    </div>
  </header>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>